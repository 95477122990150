@import '../../../../scss/theme-bootstrap';

.country-chooser {
  @include breakpoint($landscape-up) {
    display: inline-block;
  }
}

.country-select,
.country-select-img,
.language-select {
  margin-top: 4px;
  margin-#{$ldirection}: 10px;
  vertical-align: middle;
  display: inline-block;
  &--hide {
    display: none;
  }
}

.locale-select {
  display: none;
}

.country-select {
  &__menu {
    display: inline-block;
  }
}

.country-select-img {
  width: 17px;
  height: 13px;
  vertical-align: top;
  margin-top: 13px;
  margin-#{$ldirection}: 0;
}

.country-select__selectbox,
.language-select__selectbox {
  min-width: auto;
  .no-touchevents & {
    color: $color-white;
  }
  body.device-mobile & {
    color: $color-black;
  }

  // SelectBox Overrides
  .select-box__label {
    @include icon('triangle-up', after);
    min-width: auto;
    border-color: $color-black;
    text-decoration: underline;
    padding-#{$rdirection}: 15px;
    padding-#{$ldirection}: 0;
    &.open {
      text-decoration: none;
    }
    &:after {
      position: relative;
      #{$ldirection}: 8px;
      border: none;
      width: 1em;
      color: $color-white;
      font-size: 8px;
      text-decoration: none;
    }
  }
  .select-box__options {
    min-width: 155px;
    width: 100%;
    max-height: 180px;
    top: auto;
    bottom: 3.5em;
  }
  .select-box__option {
    background: $color-black;
    text-align: $ldirection;
    &:hover {
      background: $color-border;
      text-decoration: none;
    }
  }
}

.language-select__selectbox {
  padding-#{$rdirection}: 10px;

  // SelectBox Overrides
  .select-box__options {
    min-width: 112px;
  }
}
